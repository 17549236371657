"use client";

import TestimonialsSliderLayout2 from "@/components/testimonials-slider-layout-2";
import { Pagination } from "swiper/modules";
import SectionTitle from "./section-title";
import { useTranslations } from "next-intl";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const SectionTestimonialsSliderLayout2 = () => {
  const t = useTranslations("Testimonials");

  // Bangun array testimoni di dalam kode
  const testimonials = [
    {
      name: t("testimonials_1_name"),
      company: t("testimonials_1_company"),
      comment: t("testimonials_1_comment"),
      image: "/testimonials/testi-1.webp",
      rating: 5,
    },
    {
      name: t("testimonials_2_name"),
      company: t("testimonials_2_company"),
      comment: t("testimonials_2_comment"),
      image: "/testimonials/testimonial_1.jpg",
      rating: 5,
    },
    {
      name: t("testimonials_3_name"),
      company: t("testimonials_3_company"),
      comment: t("testimonials_3_comment"),
      image: "/testimonials/testi-1.webp",
      rating: 5,
    },
    {
      name: t("testimonials_4_name"),
      company: t("testimonials_4_company"),
      comment: t("testimonials_4_comment"),
      image: "/testimonials/testimonial_2.jpg",
      rating: 5,
    },
  ];

  return (
    <section className="overflow-hidden bg-gradient-to-b from-[#EBE4FA]/25 to-muted/25 py-20 dark:bg-slate-900 dark:bg-none lg:py-24">
      <div className="container relative xl:left-[calc((100vw-1312px)/4)] xl:max-w-[calc(1312px+((100vw-1312px)/2))] xl:pr-[calc((100vw-1280px)/2)]">
        <SectionTitle
          subtitle={t("subtitle")}
          sectionClasses="mx-auto max-w-xl text-center mb-12"
          titleClasses="mb-3 text-center"
          subtitleClasses="text-md font-medium"
        >
          {t("title")}
        </SectionTitle>
        <TestimonialsSliderLayout2
          testimonials={testimonials}
          swiperParams={{
            breakpoints: {
              768: {
                slidesPerView: 2,
              },
              1025: {
                slidesPerView: 2,
              },
            },
            spaceBetween: 40,
            modules: [Pagination],
            pagination: { clickable: true },
          }}
        />
      </div>
    </section>
  );
};

export default SectionTestimonialsSliderLayout2;
