"use client";
import { motion } from "framer-motion";
import IconBoxLayout2 from "@/components/icon-box-layout-2";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { getWPBaseUrl } from "@/config/site";
import { usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Html5LineIcon from "remixicon-react/Html5LineIcon";
import SmartphoneLineIcon from "remixicon-react/SmartphoneLineIcon";
import PencilRuler2LineIcon from "remixicon-react/PencilRuler2LineIcon";
import AdvertisementLineIcon from "remixicon-react/AdvertisementLineIcon";
import ImageEditLineIcon from "remixicon-react/ImageEditLineIcon";
import ServerLineIcon from "remixicon-react/ServerLineIcon";
import BrushLineIcon from "remixicon-react/BrushLineIcon";


// Mapping icon dan styles berdasarkan slug
const iconStyleMapping = {
    "web-development": {
        icon: <Html5LineIcon className="fill-primary" size={32} />,
        iconBase: "bg-[#FEE8E8]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#FA6262]",
    },
    "mobile-app-development": {
        icon: <SmartphoneLineIcon className="fill-[#44D88D]" size={32} />,
        iconBase: "bg-[#E3F9EE]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#44D88D]",
    },
    "ui-ux": {
        icon: <PencilRuler2LineIcon className="fill-[#4C86E7]" size={32} />,
        iconBase: "bg-[#D3E9FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#4C86E7]",
    },
    "digital-marketing": {
        icon: <AdvertisementLineIcon className="fill-[#7444FF]" size={32} />,
        iconBase: "bg-[#EAE3FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#7444FF]",
    },
    "content-creator": {
        icon: <ImageEditLineIcon className="fill-[#FFAF13]" size={32} />,
        iconBase: "bg-[#FFF3DC]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#FFAF13]",
    },
    "hosting": {
        icon: <ServerLineIcon className="fill-[#B939E5]" size={32} />,
        iconBase: "bg-[#FAF1FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#B939E5]",
    },
    "graphics-design": {
        icon: <BrushLineIcon className="fill-[#e56f39]" size={32} />,
        iconBase: "bg-[#FAF1FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#e56f39]",
    },
    "seo": {
        icon: <Image src="/icons/daftarlayananhome/seo-line.svg" alt="SEO Icon" width={32} height={32} className="fill-[#dae539]" />,
        iconBase: "bg-[#FAF1FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#dae539]",
    },
    // Versi Indonesia
    "jasa-pembuatan-website": {
        icon: <Html5LineIcon className="fill-primary" size={32} />,
        iconBase: "bg-[#FEE8E8]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#FA6262]",
    },
    "jasa-pembuatan-aplikasi": {
        icon: <SmartphoneLineIcon className="fill-[#44D88D]" size={32} />,
        iconBase: "bg-[#E3F9EE]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#44D88D]",
    },
    "desain-ui-ux": {
        icon: <PencilRuler2LineIcon className="fill-[#4C86E7]" size={32} />,
        iconBase: "bg-[#D3E9FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#4C86E7]",
    },
    "jasa-digital-marketing": {
        icon: <AdvertisementLineIcon className="fill-[#7444FF]" size={32} />,
        iconBase: "bg-[#EAE3FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#7444FF]",
    },
    "jasa-pembuatan-konten": {
        icon: <ImageEditLineIcon className="fill-[#FFAF13]" size={32} />,
        iconBase: "bg-[#FFF3DC]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#FFAF13]",
    },
    "layanan-hosting": {
        icon: <ServerLineIcon className="fill-[#B939E5]" size={32} />,
        iconBase: "bg-[#FAF1FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#B939E5]",
    },
    "jasa-desain-grafis": {
        icon: <BrushLineIcon className="fill-[#e56f39]" size={32} />,
        iconBase: "bg-[#FAF1FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#e56f39]",
    },
    "jasa-seo": {
        icon: <Image src="/icons/daftarlayananhome/seo-line.svg" alt="SEO Icon" width={32} height={32} className="fill-[#dae539]" />,
        iconBase: "bg-[#FAF1FF]",
        shadow: "shadow-[0_1px_6px_rgba(61,65,84,.15),0_5px_0_0_#dae539]",
    },
};

// Helper function untuk mendapatkan style berdasarkan slug
const getStyleBySlug = (slug: string, categories: any[]) => {
    // Coba dapatkan style berdasarkan slug
    const slugKey = Object.keys(iconStyleMapping).find(key => slug.includes(key));
    if (slugKey) {
        return iconStyleMapping[slugKey as keyof typeof iconStyleMapping];
    }

    // Jika tidak ada di slug, coba cari di kategori
    if (categories && categories.length > 0) {
        const categoryKey = Object.keys(iconStyleMapping).find(key =>
            categories.some(cat => cat.slug.includes(key))
        );
        if (categoryKey) {
            return iconStyleMapping[categoryKey as keyof typeof iconStyleMapping];
        }
    }

    // Default style jika tidak ada yang cocok
    return iconStyleMapping["web-development"];
};

const fadeInAnimationVariants = {
    initial: {
        opacity: 0,
        y: 60,
    },
    animate: (index: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.05 * index,
        },
    }),
};

const SectionIconBoxesLayout2 = () => {
    const t = useTranslations("SectionIconBoxesLayout2");
    const [services, setServices] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const pathname = usePathname();
    const locale = pathname.startsWith("/id") ? "id" : "en";
    const WP_REST_BASE_URL = getWPBaseUrl(locale);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch(
                    `${WP_REST_BASE_URL}/service-details?_embed&per_page=6`
                );
                const data = await response.json();
                // Transform WordPress data dengan style yang sesuai
                const transformedServices = data.map((service: any) => {
                    const categories = service._embedded?.["wp:term"]?.[0] || [];
                    const style = getStyleBySlug(service.slug, categories);

                    return {
                        ...style,
                        title: service.title.rendered.replace(/&amp;/g, '&'),
                        description: service.excerpt.rendered.replace(/<[^>]+>/g, ''),
                    };
                });
                setServices(transformedServices);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching services:", error);
                setIsLoading(false);
            }
        };
        fetchServices();
    }, [WP_REST_BASE_URL]);

    if (isLoading) {
        return (
            <section className="py-16 lg:py-24">
                <div className="container">
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-10">
                        {[...Array(6)].map((_, i) => (
                            <div key={i} className="animate-pulse">
                                <div className="h-48 bg-gray-200 rounded-lg"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="py-16 lg:py-24">
            <div className="container">
                <div className="flex justify-center">
                    <div className="text-center lg:w-3/5">
                        <h2 className="mb-12">
                            {t("subtitle")}{" "}
                            <span className="text-primary">{t("highlight")}</span>{" "}
                            {t("afhighlight")}
                        </h2>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-10">
                    {services.map((service, index) => (
                        <motion.div
                            key={service.title}
                            variants={fadeInAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{ once: true }}
                            custom={index}
                        >
                            <IconBoxLayout2 iconBox={service} />
                        </motion.div>
                    ))}
                </div>
                <div className="flex justify-center mt-12">
                    <Link href="/services/">
                        <Button size="lg">{t("button")}</Button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default SectionIconBoxesLayout2;