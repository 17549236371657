"use client";

import ProjectSlider from "@/components/projects/project-slider";
import SectionTitle from "./section-title";
import { Pagination } from "swiper/modules";
import { useTranslations } from "next-intl";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const SectionProjectsSlider = () => {
  const t = useTranslations("SectionProjectsSlider");
  return (
    <section className="py-16 lg:py-24">
      <div className="container">
        <SectionTitle
          subtitle={t("subtitle")}
          sectionClasses="mx-auto max-w-xl text-center mb-12"
          titleClasses="mb-3 text-center"
          subtitleClasses="text-md font-medium"
        >
          {t("title")}
        </SectionTitle>
        <ProjectSlider
          limit={4}
          locale=""
          swiperParams={{
            breakpoints: {
              768: {
                slidesPerView: 2,
              },
              1025: {
                slidesPerView: 2,
              },
            },
            spaceBetween: 40,
            modules: [Pagination],
            pagination: { clickable: true },
          }}
        />
      </div>
    </section>
  );
};

export default SectionProjectsSlider;
