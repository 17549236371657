"use client";

import dynamic from 'next/dynamic';
import { useTranslations } from "next-intl";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Play } from "@/components/icons/icons";
import React from 'react';

// Dynamically import DotLottieReact to prevent hydration errors
const DynamicDotLottieReact = dynamic(
  () => import('@lottiefiles/dotlottie-react').then((mod) => mod.DotLottieReact),
  { ssr: false }
);

const DynamicImage = dynamic(() => import("next/image"), { ssr: false });

const SectionBenefits = () => {
  const t = useTranslations("SectionBenefits");
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-[#EBE4FA]/25 to-muted/25 py-24 dark:bg-slate-900 dark:bg-none lg:py-32">
      <div className="container">
        <div className="flex flex-wrap items-center justify-between lg:flex-nowrap">
          <div className="relative z-[1] mb-10 lg:w-1/2">
            <DynamicImage
              src="/circles_pattern.png"
              alt="circles pattern"
              width={640}
              height={561}
              className="absolute -z-[1] -translate-y-8 scale-110 dark:opacity-10"
              loading="lazy"
            />
            <DynamicImage
              src="/benefits_img_1.jpg"
              alt="benefits"
              width={540}
              height={540}
              className="rounded-xl"
              loading="lazy"
            />
            <div className="absolute -right-10 top-1/4 w-1/2 animate-fly rounded-xl lg:w-auto">
              <DynamicImage
                src="/benefits_img_2.jpg"
                alt="benefits"
                width={320}
                height={320}
                className="rounded-xl"
                loading="lazy"
              />
              {/* <Dialog>
                <DialogTrigger className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform will-change-transform hover:scale-90">
                  <Play />
                </DialogTrigger>
                <DialogContent className="max-w-5xl border-0 p-0">
                  <DialogHeader>
                    <AspectRatio
                      ratio={16 / 9}
                      className="overflow-hidden rounded-lg"
                    >
                      <iframe
                        className="h-full w-full"
                        src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
                        allow="autoplay"
                      ></iframe>
                    </AspectRatio>
                  </DialogHeader>
                </DialogContent>
              </Dialog> */}
            </div>
          </div>
          <div className="lg:w-2/5 lg:pl-10">
            <h2 className="max-w-sm">
              {t("title")}
            </h2>
            <p className="mb-8 text-lg">
              {t("description")}
            </p>
            <div className="mb-6 flex space-x-4">
              <DynamicDotLottieReact
                src="/lottie/Check.json"
                loop
                autoplay
                className="h-8 w-10 shrink-0"
              />
              <div>
                <h3 className="mb-3 text-base">
                  {t("benefit1.title")}
                </h3>
              </div>
            </div>
            <div className="mb-6 flex space-x-4">
              <DynamicDotLottieReact
                src="/lottie/Check.json"
                loop
                autoplay
                className="h-8 w-10 shrink-0"
              />
              <div>
                <h3 className="mb-3 text-base">
                  {t("benefit2.title")}
                </h3>
              </div>
            </div>
            <div className="flex space-x-4">
              <DynamicDotLottieReact
                src="/lottie/Check.json"
                loop
                autoplay
                className="h-8 w-10 shrink-0"
              />
              <div>
                <h3 className="mb-3 text-base">
                  {t("benefit3.title")}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionBenefits;