"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { SwiperOptions } from "swiper/types";
import { useState, useEffect, useRef, useCallback } from "react";
import useSWRInfinite from "swr/infinite";
import { Skeleton } from "../ui/skeleton";
import { getWPBaseUrl } from "@/config/site";
import { usePathname } from "next/navigation";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { useTranslations } from "next-intl";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

type BlogSliderProps = {
    limit: number;
    swiperParams?: SwiperOptions;
};

type Post = {
    id: number;
    slug: string;
    title: { rendered: string };
    excerpt: { rendered: string };
    yoast_head_json?: {
        og_description: string;
        article_published_time?: string; // Tambahkan properti ini
    };
    _embedded?: {
        ["wp:featuredmedia"]?: any[];
        ["wp:term"]?: any[][];
    };
};


export default function BlogSlider({ limit, swiperParams }: BlogSliderProps) {
    const t = useTranslations("BlogSlider");
    const pathname = usePathname();
    const locale = pathname.startsWith("/id") ? "id" : "en";
    const WP_REST_BASE_URL = getWPBaseUrl(locale);

    const sliderRef = useRef<any>(null);
    const [totalItems, setTotalItems] = useState<number | null>(null);

    const { data, isLoading } = useSWRInfinite(
        (index) =>
            totalItems !== null
                ? `${WP_REST_BASE_URL}/posts?_embed&per_page=${limit}`
                : null,
        (url: string) => fetcher(url)
    );

    const getTotalItems = useCallback(async () => {
        try {
            const res = await fetch(`${WP_REST_BASE_URL}/posts?per_page=${limit}`);
            const total = Number(res.headers.get("X-WP-Total"));
            setTotalItems(total);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [limit, WP_REST_BASE_URL]);

    useEffect(() => {
        getTotalItems();
    }, [getTotalItems]);

    async function fetcher(url: string) {
        try {
            const res = await fetch(url);
            const data = await res.json();
            return data;
        } catch (error) {
            throw new Error("Failed to fetch data");
        }
    }

    const posts = data ? [].concat(...data) : [];

    const defaultSwiperParams: SwiperOptions = {
        modules: [Navigation],
        slidesPerView: 2,
        spaceBetween: 28,
        centeredSlides: false,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            568: {
                slidesPerView: 2,
                spaceBetween: 28,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 28,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
        },
        ...swiperParams,
    };

    return (
        <section className="py-24">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between gap-8">
                    <div className="w-full flex justify-between flex-col lg:w-2/5">
                        <div className="block lg:text-left text-center">
                            <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5">
                                {t("title")} <span className="text-blue-600">{t("highlight")}</span>
                            </h2>
                            <p className="text-gray-500 mb-10 max-lg:max-w-xl max-lg:mx-auto">
                                {t("description")}
                            </p>
                            <Link
                                href="/posts"
                                className={cn(
                                    "cursor-pointer border border-gray-300 shadow-sm rounded-full",
                                    "py-3.5 px-7 w-52 lg:mx-0 mx-auto flex justify-center",
                                    "text-gray-900 font-semibold transition-all duration-300 hover:bg-gray-100 dark:text-slate-100 dark:hover:text-slate-900"
                                )}
                            >
                                {t("viewAll")}
                            </Link>
                        </div>
                        <div className="flex items-center lg:justify-start justify-center lg:mt-0 mt-8 gap-8 mb-4">
                            <button
                                className="swiper-button-prev !static !mt-0 !h-11 !w-11 group flex justify-center items-center border border-solid border-blue-600 transition-all duration-500 rounded-full hover:bg-blue-600 dark:bg-slate-50 dark:hover:bg-blue-600"
                                aria-label="Previous slide"
                            >
                                <svg
                                    className="h-6 w-6 text-blue-600 group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.9999 12L4.99992 12M9.99992 6L4.70703 11.2929C4.3737 11.6262 4.20703 11.7929 4.20703 12C4.20703 12.2071 4.3737 12.3738 4.70703 12.7071L9.99992 18"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            <button
                                className="swiper-button-next !static !mt-0 !h-11 !w-11 group flex justify-center items-center border border-solid border-blue-600 transition-all duration-500 rounded-full hover:bg-blue-600 dark:bg-slate-50 dark:hover:bg-blue-600"
                                aria-label="Next slide"
                            >
                                <svg
                                    className="h-6 w-6 text-blue-600 group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 12L19 12M14 18L19.2929 12.7071C19.6262 12.3738 19.7929 12.2071 19.7929 12C19.7929 11.7929 19.6262 11.6262 19.2929 11.2929L14 6"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="w-full lg:w-3/5">
                        {isLoading ? (
                            <div className="flex gap-7">
                                {Array.from({ length: 2 }).map((_, i) => (
                                    <Skeleton key={i} className="h-[500px] flex-1" />
                                ))}
                            </div>
                        ) : (
                            <Swiper {...defaultSwiperParams} ref={sliderRef}>
                                {posts.map((post: Post) => {
                                    const featuredMedia = post._embedded?.["wp:featuredmedia"]?.[0];
                                    const featuredImageSizes =
                                        featuredMedia?.["media_details"]?.sizes;

                                    return (
                                        <SwiperSlide
                                            key={post.id}
                                            className="w-full max-lg:max-w-xl lg:w-1/2 group"
                                        >
                                            <div className="flex items-center mb-9">
                                                <Link
                                                    href={`/posts/${post.slug}`}
                                                >
                                                    {featuredMedia && featuredImageSizes && (
                                                        <Image
                                                            src={featuredImageSizes.medium_large["source_url"]}
                                                            alt={featuredMedia.alt_text}
                                                            width={featuredImageSizes.medium_large.width}
                                                            height={featuredImageSizes.medium_large.height}
                                                            className="rounded-2xl w-full object-cover"
                                                        />
                                                    )}
                                                </Link>
                                            </div>
                                            <Link
                                                href={`/posts/${post.slug}`}
                                            >
                                                <h3
                                                    className="text-xl text-gray-900 font-medium leading-8 mb-4 group-hover:text-blue-600 dark:text-slate-100 dark:hover:text-slate-400"
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${post?.title?.rendered.substring(0, 35)}${post?.title?.rendered.length > 35 ? "..." : ""
                                                            }`,
                                                    }}
                                                >
                                                </h3>
                                                {post.yoast_head_json?.article_published_time && (
                                                    <p className="text-gray-500 text-sm mb-4">
                                                        {new Date(post.yoast_head_json.article_published_time).toLocaleDateString(locale, {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                    </p>
                                                )}
                                            </Link>
                                            <div
                                                className="text-gray-500 leading-6 transition-all duration-500 mb-8"
                                                dangerouslySetInnerHTML={{
                                                    __html: `${post?.yoast_head_json?.og_description?.substring(0, 111) ||
                                                        "No description available"
                                                        }${post?.yoast_head_json?.og_description?.length ?? 0 > 111 ? "..." : ""}`,
                                                }}
                                            />
                                            <Link
                                                href={`/posts/${post.slug}`}
                                                className="cursor-pointer flex items-center gap-2 text-sm md:text-base lg:text-lg text-blue-700 dark:text-slate-100 dark:hover:text-slate-400 font-semibold"
                                            >
                                                {t("readMore")}
                                                <svg
                                                    width="15"
                                                    height="12"
                                                    viewBox="0 0 15 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.25 6L13.25 6M9.5 10.5L13.4697 6.53033C13.7197 6.28033 13.8447 6.15533 13.8447 6C13.8447 5.84467 13.7197 5.71967 13.4697 5.46967L9.5 1.5"
                                                        stroke="#4338CA"
                                                        strokeWidth="1.8"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </Link>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}