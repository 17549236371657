// src/components/JSONLD.tsx
"use client";

import { useEffect } from "react";

const JSONLD = () => {
    useEffect(() => {
        const homepageSchema = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "PT MUBAROKAH DIGITAL NUSANTARA",
            "url": "https://mubarokah.com/",
            "logo": "https://mubarokah.com/logo-light.svg",
            "sameAs": [
                "https://www.facebook.com/mubarokah.co.id/",
                "https://x.com/mubarokah_id/",
                "https://www.linkedin.com/company/mubarokah/"
            ],
            "description": "Mubarokah Digital Agency offers top-tier digital solutions for your business. Specializing in web development, mobile apps, UI/UX design, and digital marketing.",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+62 811-1433-52",
                "contactType": "Customer Service",
                "areaServed": "ID",
                "availableLanguage": ["English", "Indonesian"]
            },
            "email": "mailto:hey@mubarokah.com"
        };

        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.text = JSON.stringify(homepageSchema);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
};

export default JSONLD;
